<template>
  <div class="submit-form">
    <div v-if="!submitted">
      <div class="form-group">
        <label for="Name">Navn</label>
        <input
          type="text"
          class="form-control"
          id="Name"
          required
          v-model="caseObj.Name"
          name="Name"
        />
      </div>

      <div class="form-group">
        <label for="PackingDate">Pakka Dato</label>
        <input
          class="form-control"
          id="PackingDate"
          type="date"
          required
          v-model="caseObj.PackingDate"
          name="PackingDate"
        />
      </div>

      <div class="form-group">
        <label for="SentDate">Sent Dato</label>
        <input
          class="form-control"
          id="SentDate"
          type="date"
          required
          v-model="caseObj.SentDate"
          name="SentDate"
        />
      </div>
      <div class="form-group">
        <label for="customer">Kundi</label>
        <select
          v-model="caseObj.CustomerId"
          id="customer"
          class="form-select form-control"
          aria-label="Default select example"
        >
          <option selected>Open this select menu</option>
          <option v-for="(item, key) in customers" :key="key" :value="item.Id">
            {{ item.Name }}
          </option>
        </select>
      </div>
      
      <button @click="saveCase" class="btn mt-3 btn-success">Goym</button>
    </div>

    <!-- <div v-else>
      <h4>You submitted successfully!</h4>
      <button class="btn btn-success" @click="newCase">Add</button>
    </div> -->
  </div>
</template>

<script>
import CaseDataService from "../../services/CaseDataService";
import CustomerDataService from "../../services/CustomerDataService";
import VueMultiselect from "vue-multiselect";
import ItemDataService from "../../services/ItemDataService";

export default {
  name: "add-case",
  components: { VueMultiselect },
  data() {
    return {
      multiValue: null,
      selected: null,
      items: [],
      caseObj: {
        Id: null,
        Name: "",
        CustomerId: null,
        PackingDate: null,
        SentDate: null,
        items: [],
      },
      customers: [],
      submitted: false,
    };
  },
  methods: {
    retrieveCustomers() {
      CustomerDataService.getAll()
        .then((response) => {
          this.customers = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    saveCase() {
      var data = {
        Name: this.caseObj.Name,
        PackingDate: this.caseObj.PackingDate,
        CustomerId: this.caseObj.CustomerId,
        SentDate: this.caseObj.SentDate,
        itemsList: this.caseObj.items.map((item) => item.Id).join(","),
      };

      CaseDataService.create(data)
        .then((response) => {
          this.caseObj.Id = response.data.Id;
          console.log(response.data);
          this.submitted = true;
          this.$router.push({ name: "cases" });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    newCase() {
      this.submitted = false;
      this.caseObj = {};
    },
    retrieveItems() {
      ItemDataService.getAll()
        .then((response) => {
          this.items = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.retrieveItems();
    this.retrieveCustomers();
  },
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
